.sidebar {
  /* How this element should be laid out relative to its parent */
  flex: 1 0 10em;
  /* How children should be laid out in this element */
  display: flex;
  flex-flow: column;
  align-items: stretch;
  padding: 1em;
}

.sidebar header {
  /* Internal formatting for this element */
  text-align: right;
  font-size: 2em;
}

.sidebar ul {
  /* Internal formatting for this element */
  text-align: center;
  list-style-type: circle;
}
