.container {
  /* How this element should be laid out relative to its parent */
  position: absolute;
  width: 100vw;
  height: 100vh;
  /* Internal formatting for this element */
  /* How children should be laid out in this element */
  overflow: hidden scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
/* Hide scrollbar for Chrome, Safari and Opera */
.container::-webkit-scrollbar {
  display: none;
}

.modal {
  /* How this element should be laid out relative to its parent */
  width: 80vw;
  height: 160vh;
  margin: 10vh 10%;
  box-sizing: border-box;
  /* Internal formatting for this element */
  background: #ffffff;
  box-shadow: 1px 1px 25px 5px #000000b0;
  /* How children should be laid out in this element */
  padding: 1em;
}

.modal header {
  /* Internal formatting for this element */
  font-size: 2em;
}

.modal div {
  /* Internal formatting for this element */
  font-size: 1.5em;
}
