@value hoverTime: 0.3s;

.container {
  /* How this element should be laid out relative to its parent */
  flex: 10 0 10em;
  /* How children should be laid out in this element */
  overflow: hidden scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
/* Hide scrollbar for Chrome, Safari and Opera */
.container::-webkit-scrollbar {
  display: none;
}

.grid {
  /* How children should be laid out in this element */
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0.5em;
  padding: 1em;
}

.item {
  /* How this element should be laid out relative to its parent */
  position: relative;
  height: auto;
  /* Internal formatting for this element */
  aspect-ratio: 4/3;
  overflow: hidden;
  box-shadow: 1px 1px 5px #00000080;
  transition: box-shadow hoverTime ease;
}
.item:hover {
  /* Internal formatting for this element */
  box-shadow: 1px 1px 15px 1px #000000b0;
}

.item img {
  /* How this element should be laid out relative to its parent */
  width: 100%;
  aspect-ratio: 4/3;
  /* Internal formatting for this element */
  object-fit: cover;
}

.item div {
  /* Internal formatting for this element */
  opacity: 0.75;
  transition: opacity hoverTime linear;
}
.item:hover div {
  /* Internal formatting for this element */
  opacity: 0.9;
}

.item div header {
  /* How this element should be laid out relative to its parent */
  position: absolute;
  top: 0;
  width: 100%;
  box-sizing: border-box;
  /* Internal formatting for this element */
  padding: 1em 1em 1.5em 1em;
  background-image: linear-gradient(#ffffffdd, #ffffffdd, #ffffff00);
}

.item div header + div {
  /* How this element should be laid out relative to its parent */
  position: absolute;
  bottom: 0;
  width: 100%;
  box-sizing: border-box;
  /* Internal formatting for this element */
  padding: 1em 0.5em 0.25em 0.5em;
  background-image: linear-gradient(#ffffff00, #ffffffbb, #ffffffbb);
  font-size: 0.9em;
}
